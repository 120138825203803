import { graphql } from 'gatsby';
import { Seo } from '../components/Seo';
import { StaticImage } from 'gatsby-plugin-image';
import { DoctorsSlide } from '../components/DoctorsSlide';
//styles
import { device } from '../components/GlobalStyles';
import styled from '@emotion/styled';
//layout
import { DefaultLayout } from '../layouts/DefaultLayout';

const StyledGallery = styled.section`
    margin-top: 5em;
    padding: 0em 1em;
    h1 {
        margin-bottom: 1em;
        text-align: center;
    }
    h2 {
        margin: 1em 0em;
        border-top: 1px solid grey;
        padding-top: 1em;
        font-size: 38px;
        line-height: 48px;
        letter-spacing: -1px;
    }
    .image {
        margin: 1em 0em;
    }
    @media ${device.tablet} {
        margin-top: 8em;
        padding: 0 4em;
        .section {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: white;
            padding: 1em;
            div {
                width: 800px;
            }
            p {
                margin-right: 6em;
            }
        }
        .image {
            position: relative;
            top: 2em;
            max-width: 380px;
            margin: 1em 1em;
        }
    }
    @media ${device.laptop} {
        margin-top: 8em;
        padding: 0 4em;
        .section {
            background: white;
            padding: 1em;
            flex-direction: row;
            div {
                width: 800px;
            }
            p {
                margin-right: 6em;
                font-size: 16px;
                line-height: 28px;
            }
        }
        .image {
            position: relative;
            top: 2em;
            max-width: 380px;
            margin: 1em 1em;
        }
    }
    @media ${device.laptopL} {
        margin-top: 8em;
        .section {
            display: flex;
        }
        padding: 0 2em;
        .image {
            height: 320px;
        }
    }
    @media ${device.desktop} {
        padding: 0 4em;
        .image {
            position: relative;
            top: 2em;
            max-width: 380px;
            margin: 1em 1em;
        }
        .section {
            p {
                margin-right: 6em;
                font-size: 21px;
                line-height: 32px;
            }
        }
    }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Gallery: Page<any> = ({ data }) => {
    return (
        <main>
            <Seo
                title="Gallery – SmileTampa"
                description="Here you can view photos of our existing patients from prior treatments and procedures performed at our dental office in Tampa Florida"
            />
            <StyledGallery>
                <h1>Our Gallery</h1>
                <div className="section">
                    <div>
                        <h2>FULL MOUTH REHABILITATION</h2>
                        <p>
                            In this example, we have severe erosion, discoloration, and worn
                            dentition. For treatment of this case, we go with a full mouth upper and
                            lower crown and veneer combination restoration treatment, to restore the
                            normal height of the teeth for a complete and very natural-looking
                            smile.
                        </p>
                    </div>
                    <StaticImage className="image" src="../images/gallery/makeover-before.jpg" />
                    <StaticImage className="image" src="../images/gallery/makeover-after.jpg" />
                </div>
                <div className="section">
                    <div>
                        <h2>IMPLANTS</h2>
                        <p>
                            This case features Advanced Periodontal Disease, and what we have here
                            are missing, loose, and, discolored teeth. After the extraction of all
                            damaged teeth and placement of implants, this patient was restored to
                            full teeth with conventional upper denture and a non-removable implant
                            supported lower denture. Once again, the patient was finally able to
                            enjoy eating meals without the worry of having moving teeth.
                        </p>
                    </div>
                    <StaticImage
                        alt="dental implants pictures before"
                        className="image"
                        src="../images/gallery/implants-before.jpg"
                    />
                    <StaticImage
                        alt="dental implants after"
                        className="image"
                        src="../images/gallery/implants-after.jpg"
                    />
                </div>
                <div className="section">
                    <div>
                        <h2>EXTREME SMILE MAKEOVER</h2>
                        <p>
                            Here is an example of a case that had failing existing (or old) crowns
                            and veneers. New state of the art materials are used to help us achieve
                            an “Extreme Smile Makeover”, and we provide a more realistic and natural
                            look for the patient.
                        </p>
                    </div>
                    <StaticImage
                        alt="makeover before"
                        className="image"
                        src="../images/gallery/makeover-before.jpg"
                    />
                    <StaticImage
                        alt="makeover after"
                        className="image"
                        src="../images/gallery/makeover-after.jpg"
                    />
                </div>
                <div className="section">
                    <div>
                        <h2>VENEERS</h2>
                        <p>
                            Example of discolored and misaligned teeth that were restored with the
                            most natural-looking All-Porcelain Veneers.
                        </p>
                    </div>
                    <StaticImage
                        alt="mouth before veneers"
                        className="image"
                        src="../images/gallery/veneers-before.jpg"
                    />
                    <StaticImage
                        alt="mouth after veneers"
                        className="image"
                        src="../images/gallery/veneers-after.jpg"
                    />
                </div>
                <DoctorsSlide images={data.doctors.edges} />
            </StyledGallery>
        </main>
    );
};

export default Gallery;

export const query = graphql`
    query {
        doctors: allFile(filter: { relativeDirectory: { eq: "doctorCards" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 90, formats: [AUTO, WEBP, JPG])
                    }
                    name
                }
            }
        }
    }
`;

Gallery.Layout = DefaultLayout;
